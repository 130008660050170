<template>
  <div class="term-manage h-font-lg">
    <div class="term h-group">
        <div class="h-cell h-cell--center">
            <div class="h-cell__title">
                <van-row style="border-bottom: 1px solid #ccc; padding-bottom: 6px;">
                    <van-col span="16">
                        <van-tabs v-model="productTypeCode" type="card" color="#57ccc2" :class="{'van-tabs-cla': formData.commercial === null}">
                            <van-tab name="COMP" v-if="formData.compulsory">
                                <template #title>
                                    <span style="font-size: 12px;">
                                        <span class="icon" style="background-color:#0066FF">交</span>特别约定
                                    </span>
                                </template>
                            </van-tab>
                            <van-tab name="COMM" v-if="formData.commercial" :class="{'van-tabs-cla': formData.compulsory === null}">
                                <template #title>
                                    <span style="font-size: 12px;">
                                        <span class="icon" style="background-color:#0066FF">商</span>特别约定
                                    </span>
                                </template>
                            </van-tab>
                        </van-tabs>
                    </van-col>
                    <van-col span="8" style="text-align: right;">
                        <span @click="addTerm" v-if="formData.basis.insuredStatus === '0' && formData.basis.insuredOrgCode === 'YGBX'">
                            <van-icon name="add-o" color="#0066FF" size="14" />&nbsp;
                            <span style="color: #0066FF; font-size: 12px; font-weight: normal;s">添加</span>
                        </span>
                    </van-col>
                </van-row>
            </div>
        </div>
        <div class="h-cell__content term_content">
            <van-row v-if="productTypeCode === 'COMP' && formData.compulsory">
                <van-col class="list-item" span="24" v-for="(item, ind) in formData.compulsory.specialTermList" :key="ind">
                    <div class="term_tit">
                        <span>
                            {{ item.specialTermCode }} &nbsp; {{ item.specialTermName }}
                        </span>
                        <span style="min-width: 40px;" v-if="formData.basis.insuredStatus === '0'">
                            <van-icon name="delete" color="red" @click="delTerm(ind)" style="vertical-align: top; margin-right: 6px;"/>
                            <van-icon v-if="item.modifyFlag === '1'" :name="require('./img/edit.png')" size="16px" @click="edit(ind)"/>
                        </span>
                    </div>
                    <div class="term_text">
                        <span v-if="!item.isEdit">{{ item.specialTermText }}</span>
                        <van-field v-else v-model="item.specialTermText" maxlength="300" type="textarea" placeholder="请输入" border @blur="termBlur(ind)" />
                    </div>
                </van-col>
            </van-row>
            <van-row v-if="productTypeCode === 'COMM' && formData.commercial">
                <van-col class="list-item" span="24" v-for="(item, ind) in formData.commercial.specialTermList" :key="ind">
                    <div class="term_tit">
                        <span>
                            {{ item.specialTermCode }} &nbsp; {{ item.specialTermName }}
                        </span>
                        <span style="min-width: 40px;" v-if="formData.basis.insuredStatus === '0'">
                            <van-icon name="delete" color="red" @click="delTerm(ind)" style="vertical-align: top; margin-right: 6px;"/>
                            <van-icon v-if="item.modifyFlag === '1'" :name="require('./img/edit.png')" size="16px" @click="edit(ind)"/>
                        </span>
                    </div>
                    <div class="term_text">
                        <span v-if="!item.isEdit">{{ item.specialTermText }}</span>
                        <van-field v-else v-model="item.specialTermText" maxlength="300" type="textarea" placeholder="请输入" border @blur="termBlur(ind)" />
                    </div>
                </van-col>
            </van-row>
        </div>
    </div>
    <!-- 添加的条款 -->
    <van-popup v-model="addTermShow" round position="bottom">
        <div class="term_content_list">
            <p class="list_title">特别约定</p>
            <van-row class="list" v-if="termData.length">
                <van-col class="list-item" span="24" v-for="(item, ind) in termData" :key="ind">
                    <van-checkbox v-model="item.checked" shape="square" label-position="left" icon-size="16px" checked-color="#57ccc2" >
                        <div>
                            <div class="term_tit">
                                <span>
                                    {{ item.specialTermCode }} &nbsp; {{ item.specialTermName }}
                                </span>
                            </div>
                            <div class="term_text">
                                <span>{{ item.specialTermText }}</span>
                            </div>
                        </div>
                    </van-checkbox>
                </van-col>
            </van-row>
            <div v-else>
                <van-image
                    :src="require('@/assets/images/none_data.jpg')"
                    class="empy-img"
                />
            </div>
            <van-row class="btn-bottom">
                <van-button type="primary" style="margin-right: 40px;" block round @click="confirm">确定</van-button>
                <van-button type="default" style="border: 1px solid rgb(87, 204, 194); color: rgb(87, 204, 194);" block round @click="addTermShow = false">取消</van-button>
            </van-row>
        </div>
    </van-popup>
</div>
</template>
<script>
import { request } from "@/api/service";
import { Toast, Notify, Dialog } from "vant";
import { mapState } from "vuex";
import common from '../../common/mixin/common.js'
export default {
  name: "termManage",
  components: {},
  mixins: [common],
  props: {
    formData: {
      type: Object,
      default: () => {
          return {
            basis: {},
            commercial: {},
            vehicle: {},
            compulsory: {}
          }
      }
    }
  },
  data() {
    return {
      quote: {
        basis: {},
        commercial: {}
      },
      id: null,
      productTypeCode: 'COMM',
      commTermData: [],
      compTermData: [],
      termData: [],
      addTermShow: false,
    };
  },
  computed: {
    ...mapState("car", ["quoteRes"]),
    ...mapState("user", ["info"])
  },
  async created() {
    // const {id}=this.$route.query
    // this.id = id
    // if(!id){
    //   return false
    // }
    // await this.getDetail(id, 'detail')
    // console.log(this.formData, 'formDataformDataformData')
  },
  methods: {
    addTerm () {
        this.addTermShow = true
        this.getList()
    },
    getList () {
        request({
            url: '/afis-auto-web/config/autoCfgSpecialTerm/list',
            method: 'post',
            data: {
                page: 1,
                pageSize: 1000,
                productTypeCode: this.productTypeCode,
                orgId: this.info.orgId,
                insuredOrgCode: this.formData.basis.insuredOrgCode
            }
      }).then(res => {
          res.list.map(item => {
              if (item.modifyFlag === '1') {
                  this.$set(item, 'isEdit', true)
              } else {
                  this.$set(item, 'isEdit', false)
              }
              this.$set(item, 'checked', false)
              this.$set(item, 'isDel', true)
          })
          this.termData = res.list
      })
    },
    edit (ind) {
        if (this.productTypeCode === 'COMP') {
            this.$set(this.formData.compulsory.specialTermList[ind], 'isEdit', !this.formData.compulsory.specialTermList[ind].isEdit)
        } else {
            this.$set(this.formData.commercial.specialTermList[ind], 'isEdit', !this.formData.commercial.specialTermList[ind].isEdit)
        }
    },
    termBlur (ind) {
        if (this.productTypeCode === 'COMP') {
            this.$set(this.formData.compulsory.specialTermList[ind], 'isEdit', false)
        } else {
            this.$set(this.formData.commercial.specialTermList[ind], 'isEdit', false)
        }
    },
    confirm () {
        let selectedData = this.termData.filter(item => item.checked)
        selectedData.map(item => {
            item.isEdit = false
        })
        if (!selectedData.length) {
            Toast("请选择数据")
            return false
        }
        this.formData.compulsory.specialTermList = !this.formData.compulsory.specialTermList ? [] : this.formData.compulsory.specialTermList
        this.formData.commercial.specialTermList = !this.formData.commercial.specialTermList ? [] : this.formData.commercial.specialTermList
        if (this.productTypeCode === 'COMP') {
            this.formData.compulsory.specialTermList = [...this.formData.compulsory.specialTermList, ...selectedData]
        } else {
            this.formData.commercial.specialTermList = [...this.formData.commercial.specialTermList, ...selectedData]
        }
        Toast.success("添加成功")
        this.addTermShow = false
    },
    arrayUnique (arr, name) {
        var hash = {};
        return arr.reduce(function (item, next) {
            hash[next[name]] ? '' : hash[next[name]] = true && item.push(next);
            return item;
        }, []);
    },
    delTerm (ind) {
        Dialog.confirm({
            title: '提示',
            message: '确定要删除吗',
        }).then(res => {
            if (this.productTypeCode === 'COMP') {
                this.formData.compulsory.specialTermList.splice(ind, 1)
            } else {
                this.formData.commercial.specialTermList.splice(ind, 1)
            }
        })
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;
}
.h-cell__content{
  padding: 6px 8px;
  font-size: $font-size-sm;
  ::v-deep .van-row {
    padding:9px 0;
  }
}
.h-cell__title{
  font-weight: $font-weight-bold-lg;
  font-size: $font-size-md;
  span.icon {
    border-radius: $border-radius-sm;
    color: $white;
    padding: 3px;
    margin-right:6px;
  }
}
.h-cell{
  padding: 10px 8px;
}
.base{
  .warn{
    text-indent: -4px;
    padding-left: 12px;
    font-size: $font-size-xs;
    color: $primary-color;
  }
}
.term-manage{
    background-color: $background-color;
    &_head{
      padding: 10px 2px;
      color:$white;
      font-size: $font-size-xl;
    }
    &_content{
      padding: 10px;
    // background: url('../result/image/bg-title.png') no-repeat fixed;
      background-size: 100% 260px;
      padding-bottom: 40px;
    }
    &_foot{
      background-color: $white;
      .total{
        font-size: $font-size-md;
        font-weight: $font-weight-bold-lg;
        padding: 5px;
        span{
          font-size: $font-size-xl;
          color: $red;
        }
      }
      .detail{
        font-size: $font-size-sm;
        color: $gray-5;
        span{
          padding:0 5px;
          border-right: $border-color solid $border-width-xs;
          &:last-child{
            border-right-width:0;
          }
        }
      }
    }
    .h-group{
      margin-bottom: 10px;
      padding: 8px;
      background-color: $white;
      border-radius: $border-radius-lg;
      box-shadow: $box-shadow-base;
    }
    .quote-result_bnt {
        height: 60px;
        .success {
            text-align: center;
        }
    }
    .quote-result_bnt--fixed {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99;
        background-color: #fff;
        padding: 10px;
        border-top: #f7f8fa solid 2px;
        button {
            display: inline-block;
            min-width: 40px;
            margin-right: 6px;
            height: 32px;
        }
        .total{
        font-size: $font-size-md;
        font-weight: $font-weight-bold-lg;
        padding: 5px;
        span{
          font-size: $font-size-xl;
          color: $red;
        }
      }
      .detail{
        font-size: $font-size-sm;
        color: $gray-5;
        span{
          padding:0 5px;
          border-right: $border-color solid $border-width-xs;
          &:last-child{
            border-right-width:0;
          }
        }
      }
    }
    .refresh {
      vertical-align: bottom;
      margin-left: 4px;
      display: inline-block;
    }
  }
  .quote-detail_content {
    .total{
      font-size: $font-size-md;
      font-weight: $font-weight-bold-lg;
      padding: 5px;
      span{
        font-size: $font-size-xl;
        color: $red;
      }
    }
    .detail{
        font-size: $font-size-sm;
        color: $gray-5;
        span{
          padding:0 5px;
          border-right: $border-color solid $border-width-xs;
          &:last-child{
            border-right-width:0;
          }
        }
      }
  }
::v-deep .van-tabs__nav--card {
    margin: 0;
}
.term_content {
    position: relative;
    top: -20px;
    .term_tit {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        word-wrap: break-all;
        word-break: break-all;
    }
    .term_text {
        padding-left: 10px;
        color: rgb(121, 121, 121);
        margin-top: 6px;
        font-size: 14px;
        word-wrap: break-all;
        width: 100%;
        word-break: break-all;
    }
    .van-field {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
}
.term_content_list {
    padding: 0 10px 10px 10px;
    margin-bottom: 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    .list {
        height: 300px;
        overflow-y: auto;
    }
    .list-item {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .list_title {
        font-size: 18px;
    }
    .term_tit {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        word-wrap: break-all;
        word-break: break-all;
    }
    .term_text {
        padding-left: 10px;
        color: rgb(121, 121, 121);
        margin-top: 6px;
        font-size: 14px;
        word-wrap: break-all;
        word-break: break-all;
        width: 100%;
    }
    .van-field {
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    .btn-bottom {
        text-align: center;
        margin-top: 10px;
        button {
            width: 40%;
            display: inline-block;
        }
    }
}
::v-deep .empy-img img {
    width: 100px;
    display: inline-block;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50%;
    position: relative;
    left: 50px;
}
::v-deep .van-tabs-cla {
    width: 50%;
}
::v-deep .van-checkbox__label {
    width: 90%;
}
::v-deep .van-checkbox {
    position: relative;
}
::v-deep .van-checkbox__icon {
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
