import parseFilters from "@/utils/parseFilters";
import areaJson from "@/assets/data/area";
import dayjs from "dayjs";
import store from "@/store";
import { isArray } from "lodash";
const funMap = {
  toThousandFixed: function(num, digits) {
    const number = (Number(num) || 0).toFixed(digits);
    return number.toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
  },
  dateFormat: function(val, format) {
    if (!val) {
      return null;
    }
    return dayjs(val).format(format || "YYYY-MM-DD");
  },
  dictFormat: function(val, dictCode) {
    if (!(String(val) && dictCode)) {
      return null;
    }
    const dictOption = store.getters.dict[dictCode] || store.getters.dict[dictCode.toUpperCase()];
    const filterOption = dictOption
      ? dictOption.filter(dict => {
          return dict.value == val;
        })
      : [];
    return filterOption[0] && filterOption[0].label;
  },
  areaFormat: function() {
    const val = Array.prototype.slice.call(arguments);
    if (!val || !isArray(val)) {
      return val;
    }
    const iterationText = function(valList, options) {
      val.forEach(itemVal => {
        const itemOption = options.filter(item => {
          return item.value == itemVal;
        });
        if (itemOption.length > 0) {
          const nextItem = itemOption[0];
          valList.push(nextItem.label);
          nextItem.children && iterationText(valList, nextItem.children);
        }
      });
    };
    const valList = [];
    iterationText(valList, areaJson);
    return valList.join("/");
  },
  optionFormat:function(val,optionMap){
    return optionMap && optionMap[val];
  }
};
const identity = function(_) {
  return _;
};
/**
 * Resolve an asset.
 * This function is used because child instances need access
 * to assets defined in its ancestor chain.
 */
function _f(id) {
  /* istanbul ignore if */
  if (typeof id !== "string") {
    return identity;
  }
  const assets = funMap[id];
  // check local registration variations first
  if (assets) {
    return assets;
  }
  console.warn("Failed to resolve " + id, funMap);
  return identity;
}
export default function formatFilter(val, aisle) {
  const expression = parseFilters(`${val || null} | ${aisle}`);
  return eval(expression);
}
