<template>
  <div>
    <van-row>
      <van-col span="8" class="h-text-color_gray-6">{{riskItem.riskName}}</van-col>
      <van-col span="8">
          <span v-if="pageData.amountCode.includes(riskItem.riskCode)">{{riskItem.amount}}</span>
          <span v-if="riskItem.deductibleRate">{{ riskItem.deductibleRate * 100 }}%</span>
          <span v-if="riskItem.quantity && !riskItem.unitAmount && !riskItem.serviceList">{{ riskItem.quantity }}次</span>
          <span v-if="pageData.unitCode.includes(riskItem.riskCode)">{{ riskItem.unitAmount }} x {{ riskItem.quantity }}</span>
          <span v-if="riskItem.serviceList&&riskItem.serviceList.length>1">
            <ClauseView :list="riskItem.serviceList" />
          </span>
      </van-col>
      <van-col span="8" class="h-text-right h-price-color">￥<span class="h-font-md">{{riskItem.actualPremium}}</span></van-col>
    </van-row>
    <div v-if="riskItem.riskCode==='COMM1M02'||riskItem.riskCode==='COMM1M03'">
      <TableView v-if="formData.chargePileList" title="充电桩详细信息" :list="formData.chargePileList" :columns="chargePile.columns" :viewConfig="chargePile.viewConfig"/>
    </div>
    <div v-else-if="riskItem.riskCode==='COMM0K01'||riskItem.riskCode==='COMM1K01'">
      <TableView v-if="riskItem.deviceList" title="新增设备详细信息" :list="riskItem.deviceList" :columns="device.columns" :viewConfig="device.viewConfig"/>
    </div>
  </div>
</template>
<script>
import TableView from './TableView'
import ClauseView from './ClauseView'
export default {
  name: 'Commercial',
  inheritAttrs: false,
  components: {TableView,ClauseView},
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    riskItem:{
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageData:{
        amountCode: [
          'COMM0A01', 'COMM1A01', 'COMM0A02', 'COMM1A02', 'COMM0A03', 
          'COMM1A03', 'COMM0B01', 'COMM1B01', 'COMM0B02', 'COMM1B02', 
          'COMM0B03', 'COMM1B03', 'COMM0C01', 'COMM1C01', 'COMM0C03', 
          'COMM1C03', 'COMM0D02', 'COMM1D02', 'COMM0B05', 'COMM1B05', 
          'COMM0C02', 'COMM1C02', 'COMM0M01', 'COMM1M01', 'COMM0M03'],
        unitCode:['COMM0D01', 'COMM1D01', 'COMM0D03', 'COMM1D03', 'COMM0E01', 'COMM1E01', 'COMM1M02', 'COMM1M03']
      },
      device:{
        columns:[
          {code:"toolName",label:"设备名称"},
          {code:"actualValue",label:"实际价值"}
        ],
        viewConfig:{
          fmCode: "risk",
          show: true,
          labelWidth: "7em",
          formItems:[
            {
              prop: "toolName",
              view: true,
              label: "设备名称"
            },
            {
              prop: "quantity",
              view: true,
              label: "数量"
            },
            {
              prop: "productArea",
              view: true,
              filters: "optionFormat({'0':'国产','1':'进口'})",
              label: "产地"
            },
            {
              prop: "brand",
              view: true,
              label: "品牌"
            },
            {
              prop: "actualValue",
              view: true,
              label: "实际价值"
            },
            {
              prop: "purchasingPrice",
              view: true,
              label: "购买金额"
            },
            {
              prop: "purchaseDate",
              view: true,
              filters: "dateFormat()",
              label: "购买时间"
            }
          ]
        }
      },
      chargePile:{
        columns:[
          {code:"kind",label:"充电桩种类",filters: "optionFormat({'1':'地面充电桩','2':'壁挂式充电桩','9':'其他类充电桩'})"},
          {code:"model",label:"充电桩型号"}
        ],
        viewConfig:{
          fmCode: "risk",
          show: true,
          labelWidth: "7em",
          formItems:[
            {
              prop: "kind",
              view: true,
              filters: "optionFormat({'1':'地面充电桩','2':'壁挂式充电桩','9':'其他类充电桩'})",
              label: "充电桩种类"
            },
            {
              prop: "model",
              view: true,
              label: "充电桩型号"
            },
            {
              prop: "code",
              view: true,
              label: "充电桩编号"
            },
            {
              prop: "serviceLift",
              view: true,
              filters: "dictFormat('CHARGEPILE_SERVICE_LIFT')",
              label: "使用年限"
            },
            {
              prop: "installDate",
              view: true,
              filters: "dateFormat()",
              label: "安装日期"
            },
            {
              prop: "addressType",
              view: true,
              filters: "optionFormat({'1':'地面停车场','2':'地面停车楼/地下停车场'})",
              label: "安装地点类型"
            },
            {
              prop: "areaCodes",
              view: true,
              filters: "areaFormat()",
              label: "安装地点"
            },
            {
              prop: "address",
              view: true,
              label: "安装详细地址"
            }            
          ]
        }
      }
    }
  },
  created () {

  },
  methods: {
    init () {
      // 初始化 model
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
</style>
