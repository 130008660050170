<template>
  <div>
    <van-form ref="Form" v-bind="{ ...$attrs, ...comProps }" :model="form">
      <van-row gutter="20">
        <template v-for="(item) in formItems()">
          <slot :name="`${fmCode}_${item.prop}`" :form="form" :config="item">
              <van-col v-if="item.component==='HUpload'" :key="item.prop" :span="item.col" class="uploader">
                <van-uploader v-model="form[item.prop]" :max-count="item.limit || 1" :deletable="false" readonly multiple>
                  <template #preview-cover="{ file }">
                    <div v-if="file" class="preview-cover van-ellipsis">{{ file.name }}</div>
                  </template>
                  <van-image v-if="item.iconImgUrl" width="100%" height="100px" fit="contain" :src="require('@/assets/images/upload/testReport.png')">
                  </van-image>
                </van-uploader>
                <div v-if="item.label||item.config.label" class="uploader-label">{{item.label||item.config.label}}</div>
              </van-col>
              <van-field v-else :key="item.prop" :label="item.label||item.config.label">
                <template #input>
                    <div v-html="item.filters?formatFilter(form[item.prop],item.filters):form[item.prop]" />
                </template>
                <template #right-icon>
                  <span v-if="item.append">{{ item.append }}</span><slot name="right-icon"></slot>
                </template>
              </van-field>
          </slot>
        </template>
      </van-row>
    </van-form>
  </div>
</template>
<script>
import formatFilter from '@/utils/format'
export default {
  name: 'HYFormView',
  inheritAttrs: false,
  components: { },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pageForm: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pageLayout: {
      type: Array,
      default: () => {
        return {
        }
      }
    },
    pageLayoutMap: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    pageScript:{
      type: Function,
      default: null
    }
  },
  props: {
    fmCode:{
      type: String,
      default: null
    },
    form: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    form:{
      handler (newVal, oldVal) {
        this.config.formItems.map(formItem => {
          if(formItem.val === newVal[formItem.prop]){
            return true
          }
          formItem.val=newVal[formItem.prop]
        })
      },
      deep:true
    }
  },
  data () {
    return {
      formContext:this
    }
  },
  computed: {
    comProps () {
      return Object.assign({
        inline: false,
        inputAlign:'right',
        errorMessageAlign:'right',
        showError: false,
        labelWidth: '60px',
        scrollToError:true,
        show: true,
        disabled: false,
        size: 'mini',
        label: '',
        formItems: []
      }, this.config)
    }
  },
  created () {
    //this.init()
  },
  methods: {
    formatFilter,
    init () {
      // 初始化 model
      this.initForm()
      this.initWatch()
    },
    initForm () {
      this.formItems().map(formItem => {
        if(!formItem.prop){
          return false
        }
        if(this.form[formItem.prop]){
          formItem.val = this.form[formItem.prop]
          return false
        }
        this.$set(this.form, formItem.prop, formItem.val || null)
      })
    },
    initWatch(){
      this.pageScript.prototype.pageContext=this.pageContext
      this.pageScript.prototype.pageForm=this.pageForm
      this.pageScript.prototype.pageLayout=this.pageLayout
      this.pageScript.prototype.pageLayoutMap=this.pageLayoutMap
      this.config.formItems.forEach(formItem => {
        if(!formItem.prop){
          return false
        }
        if(!formItem.watch){
          return false
        }
        const watchThat=new this.pageScript()
        watchThat.form=this.form
        watchThat.ctx=this
        this.$watch(`form.${formItem.prop}`,(newVal)=>{
          watchThat.val=newVal
          const fun=new Function('pageCtx','ctx','val',`${formItem.watch}`)

          fun.call(watchThat,this.pageContext,this,newVal)
        })
      })
    },
    formItems () {
      return this.config.formItems.filter(item => {
          const pageForm=this.pageForm
          const form=this.form
          return eval(item.view)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.uploader{
  // display: inline-block;
  text-align: center;
  .uploader-label{
    font-size: 12px;
    color:#b7b7b7;
    padding: 2px;
    span{
      color: #ee0a24;
    }
  }
}
::v-deep .van-uploader__preview{
  height: 100px;
}
</style>
