<template>
  <div class="table" >
    <div class="h-m-t-5">
      <table class="table">
        <tr>
          <th>序号</th>
          <template v-for="column in columns" >
            <th :key="column.code">{{column.label}}</th>
          </template>
          <th>操作</th>
        </tr>
        <tr v-for="(item,index) in list" :key="index">
          <td>{{index+1}}</td>
          <template v-for="column in columns" >
            <td :key="column.code">{{column.filters?formatFilter(item[column.code],column.filters):item[column.code]}}</td>
          </template>
          <td class="h-primary-color" @click="viewOpt(item)">查看</td>
        </tr>
      </table>
    </div>
    <div class="tableForm" id="tablePopup">
      <van-popup v-model="pageData.popupViewShow" round closeable position="bottom" teleport="#tablePopup" >
        <div class="title">
          {{title}}
        </div>
        <div class="viewContent">
          <HYFormView
            fmCode="risk"
            :config="viewConfig"
            :form="viewItem">
          </HYFormView>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import HYFormView from "@/components/FormView.vue";
import {cloneDeep} from "lodash"
import formatFilter from "@/utils/format";
export default {
  name: "TableView",
  inheritAttrs: false,
  components: {HYFormView},
  props: {
    title:{
      type: String,
      default: "信息查看"
    },
    list: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    viewConfig:{
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      pageData:{
        popupViewShow:false
      },
      viewItem:{}
    }
  },
  methods: {
    formatFilter,
    viewOpt(item){
      this.viewItem = cloneDeep(item)
      this.pageData.popupViewShow = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.table{
  width: 100%;
  border-collapse: collapse;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  background-color: #fff;
  font-size: 14px;
  color: #606266;
  th,td{
    border:1px solid #ebeef5;
    padding:6px 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  th:first-child{
    width: 40px;
  }
  th:last-child{
    width: 50px;
  }
  .tableForm{
    .van-popup{
      height: 70vh;
    }
    .title{
      height: 50px;
      line-height: 50px;
      text-align: center;
      white-space: nowrap;
      font-size: $font-size-xl;
      font-weight: $font-weight-bold-lg;
      border-bottom: 1px dashed $primary-color;
    }
    .viewContent{
      position: relative;
      height: calc(100% - 50px);
      overflow: scroll;
    }
    .content{
      position: relative;
      height: calc(100% - 50px - 55px);
      overflow: scroll;
      .btn{
        position: relative;
        .van-button{
          width: 100px;
        }
        .fixed{
          height: 45px;
          position: fixed; 
          left: 0;
          right: 0;
          bottom: 0;
          background: #fff;
          padding: 5px 0;
          // padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
          // padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
        }
      }
    }
  }
}
</style>