<template>
  <div class="table" >
    <div class="h-primary-color" @click="show">查看</div>
    <div class="tableForm" id="tablePopup">
      <van-popup v-model="pageData.popupShow" round closeable position="bottom" teleport="#tablePopup" >
        <div class="title">
          条款项
        </div>
        <div class="content">
          <div class="h-p-5">
            <template v-for="(item,index) in list">
              <div v-if="item.quantity" :key="index" class="h-flex h-row-between h-p-5">
                <div>{{item.serviceDepict}}</div>
                <div class="h-primary-color h-text-nowrap">{{item.quantity}} 次</div>
              </div>
            </template>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CauseView',
  inheritAttrs: false,
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      pageData:{
        popupShow:false,
      }
    }
  },
  methods: {
    show(){
      this.pageData.popupShow = true
    },
   
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/css/cell.scss";
.tableForm{
  .van-popup{
    height: 70vh;
  }
  .title{
    height: 50px;
    line-height: 50px;
    text-align: center;
    white-space: nowrap;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold-lg;
    border-bottom: 1px dashed $primary-color;
  }
  .content{
    position: relative;
    height: calc(100% - 50px - 55px);
    overflow: scroll;
    ::v-deep .van-stepper{
      display: flex;
    }
    .btn{
      position: relative;
      .van-button{
        width: 100px;
      }
      .fixed{
        height: 45px;
        position: fixed; 
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 5px 0;
        // padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
        // padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
      }
    }
  }
}
</style>